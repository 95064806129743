import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'
import { loadStripe } from '@stripe/stripe-js'

async function initialize() {
  const stripe = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)
  const fetchClientSecret = async () => {
    const response = await axios({
      method: 'post',
      url: '/gifts/checkout_session',
      headers: { 'X-CSRF-Token': accessToken },
    })

    return response.data.client_secret
  }

  const checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret,
  })

  checkout.mount('#checkout')
}

window.loadStripeCheckout = () => initialize()
