import React from 'react'
import { useDispatch } from 'react-redux'
import { setVerified } from '@/public/redux/recaptchaSlice'
import GoogleReCaptcha from './GoogleReCaptcha'

export default function GoogleReCaptchaVerifier() {
  const dispatch = useDispatch()

  return (
    <GoogleReCaptcha onVerify={() => dispatch(setVerified(true))} />
  )
}
