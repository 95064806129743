/* eslint-disable react/require-default-props */
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import PropTypes from 'prop-types'

function promiseWithResolvers() {
  let resolve
  let reject

  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })

  return { promise, resolve, reject }
}

const GoogleReCaptchaContext = createContext({
  executeRecaptcha: () => {
    // This default context function is not supposed to be called
    throw Error(
      'GoogleReCaptcha Context has not yet been implemented, if you are using useGoogleReCaptcha hook, make sure the hook is called inside component wrapped by GoogleRecaptchaProvider',
    )
  },
})

const { Consumer: GoogleReCaptchaConsumer } = GoogleReCaptchaContext

export function GoogleReCaptchaProvider({ children }) {
  const recaptchaLoadedPromiseRef = useRef(promiseWithResolvers())

  useEffect(() => {
    const handleRecaptchaLoaded = () => {
      recaptchaLoadedPromiseRef.current.resolve(true)
    }
    if (!window.grecaptchaHandle) {
      window.addEventListener('recaptchaLoaded', handleRecaptchaLoaded)
    } else {
      recaptchaLoadedPromiseRef.current.resolve(true)
    }

    return () => {
      window.removeEventListener('recaptchaLoaded', handleRecaptchaLoaded)
    }
  }, [])

  const executeRecaptcha = useCallback(async (action) => {
    await recaptchaLoadedPromiseRef.current.promise
    if (!window.grecaptcha.enterprise.execute) {
      throw new Error(
        '<GoogleReCaptchaProvider /> Google Recaptcha has not been loaded',
      )
    }

    return window.grecaptcha.enterprise.execute(window.grecaptchaHandle, { action })
  }, [])

  const googleReCaptchaContextValue = useMemo(() => ({ executeRecaptcha }), [executeRecaptcha])

  return (
    <GoogleReCaptchaContext.Provider value={googleReCaptchaContextValue}>
      {children}
    </GoogleReCaptchaContext.Provider>
  )
}

export { GoogleReCaptchaConsumer, GoogleReCaptchaContext }
export default GoogleReCaptchaProvider

GoogleReCaptchaProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
