/* eslint-disable react/require-default-props */
import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useGoogleReCaptcha } from './use-google-recaptcha'

export default function GoogleReCaptcha({
  action,
  onVerify,
  refreshReCaptcha,
}) {
  const googleRecaptchaContextValue = useGoogleReCaptcha()

  useEffect(() => {
    const { executeRecaptcha } = googleRecaptchaContextValue

    if (!executeRecaptcha) {
      return
    }

    const handleExecuteRecaptcha = async () => {
      const token = await executeRecaptcha(action)

      onVerify(token)
    }

    handleExecuteRecaptcha()
  }, [action, onVerify, refreshReCaptcha, googleRecaptchaContextValue])
  return null
}

GoogleReCaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  action: PropTypes.string,
  refreshReCaptcha: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
}
